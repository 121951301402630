import {Injectable} from '@angular/core';
import {User} from '../models/User';
import {UserFirestore} from '../models/firestore/UserFirestore';
import {AngularFirestore, DocumentChangeAction, DocumentReference} from '@angular/fire/compat/firestore';
import {filter, map, pairwise, switchMap, take, tap} from 'rxjs/operators';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {CourseFirestore} from '../models/firestore/CourseFirestore';
import {Course} from '../models/Course';
import {mapCourseFromFirestore} from '../utils';
import {TransportCompany} from '../models/TransportCompany';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {GeoPoint} from '../models/GeoPoint';
import {Hub, kSensor} from '../models/firestore/SensorFirestore';
import {environment} from '../../../environments/environment';
import {MessageFirestore} from '../models/firestore/MessageFirestore';
import {Message} from '../models/Message';
import {StorageService} from './storage.service';
import {Alert} from '../models/Alert';
import {LegalEntity} from '../models/LegalEntity';
import {TransportCompanyFirestore} from '../models/firestore/TransportCompanyFirestore';
import {Facility} from '../models/Facility';
import {PDF} from '../../pages-gestion/gestion/gestion.page';

@Injectable({
  providedIn: 'root'
})

export class GlobalService {
  public currentUid: string;
  resitrationURL = environment.registrationURL;
  coursesReplaySubject: ReplaySubject<Course[]> = new ReplaySubject<Course[]>(1);
  legalEntityReplaySubject: ReplaySubject<LegalEntity> = new ReplaySubject<LegalEntity>(1);
  allLegalEntityReplaySubject: ReplaySubject<LegalEntity[]> = new ReplaySubject<LegalEntity[]>(1);
  facilitiesReplaySubject: ReplaySubject<Facility[]> = new ReplaySubject<Facility[]>(1);
  userReplaySubject: ReplaySubject<User> = new ReplaySubject<User>(1);
  messagesReplaySubject: ReplaySubject<Message[][]> = new ReplaySubject<Message[][]>(1);
  alertsReplaySubject: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  navBarDisabled: boolean;
  notifSuvis: boolean;
  readonly courses$: Observable<Course[]> = this.coursesReplaySubject.asObservable();
  readonly alerts$: Observable<Array<Array<Alert>>> = this.alertsReplaySubject.asObservable();
  readonly legalEntity$: Observable<LegalEntity> = this.legalEntityReplaySubject.asObservable().pipe(filter(e => e !== undefined));
  readonly allLegalEntity$: Observable<LegalEntity[]> = this.allLegalEntityReplaySubject.asObservable();
  readonly allLegalFacilities$: Observable<Facility[]> = this.facilitiesReplaySubject.asObservable();
  readonly user$: Observable<User> = this.userReplaySubject.asObservable().pipe(filter(u => u !== undefined));
  readonly allMessages$: Observable<Message[][]> = this.messagesReplaySubject.asObservable().pipe(filter(m => m !== undefined));

  // Utilisation de BehaviorSubject
  // idée : https://stackoverflow.com/questions/57636203/subscribe-to-observable-vs-subscribe-to-subject/57636483#57636483
  // exemple : https://stackoverflow.com/questions/53777521/angular-6-http-observable-subject-across-multiple-components/53777797#53777797

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private storageService: StorageService) {
    this.init();
  }

  getCurrentUserUid(): Promise<string> {
    return new Promise(resolve => {
      this.afAuth.authState.subscribe(value => {
        resolve(value.uid);
      });
    });
  }

  getDriverInfos(uid: string): Observable<User> {
    return this.afs.doc<UserFirestore>('users/' + uid).valueChanges().pipe(take(1), map(userData => ({uid, ...userData} as User)));
  }

  addActionNotice(msgTranslationKey: string, idCourse: string, stepAfter: number = null, newMembers: Array<string> = null,
                  pocheType: string = null, etabRole: string = null, nbPoche: string = null, oldClient = null) {
    const actionNotice = this.actionNotice(msgTranslationKey, idCourse, stepAfter, newMembers, pocheType, etabRole, nbPoche, oldClient);
    return this.afs.collection('/courses').doc(idCourse).collection('/messages').add(actionNotice);
  }
  actionNotice(msgTranslationKey: string, idCourse: string, stepAfter: number = null, newMembers: Array<string> = null,
                  pocheType: string = null, etabRole: string = null, nbPoche: string = null, oldClient = null) {
    return {
      date: new Date(),
      message: msgTranslationKey,
      userId: this.currentUid,
      type: 'ACTION_NOTICE',
      stepAfter,
      newMembersIds: newMembers,
      pocheType,
      etabRole,
      nbPoche,
      oldClient
    };
  }

  getCoursesList(uid: string): Observable<DocumentChangeAction<CourseFirestore>[]> {
    return this.afs.collection<CourseFirestore>('courses', ref => ref.where('members', 'array-contains', uid)).snapshotChanges();
  }

  getCompanyInfos(ref: DocumentReference): Observable<TransportCompany> {
    if(ref){
      return this.legalEntity$.pipe(take(1),switchMap(entity => entity ?
        this.afs.doc<TransportCompany>(ref).get()
          .pipe(map(snap => ({id: snap.id, ...snap.data()} as TransportCompany))) : undefined));
    } else {
      return of(null);
    }
  }
  async addCompany(data: TransportCompanyFirestore): Promise<DocumentReference<TransportCompanyFirestore>> {
    const nofinessej = (await this.legalEntity$.pipe(take(1)).toPromise())?.nofinessej;
    if(!nofinessej) {
      throw new Error('USER_NO_ETAB');
    }
    return this.afs.collection<TransportCompanyFirestore>('legal_entities/' + nofinessej + '/transport_companies/').add(data);
  }
  async editCompany(companyId: string, data: TransportCompanyFirestore): Promise<void> {
    const nofinessej = (await this.legalEntity$.pipe(take(1)).toPromise())?.nofinessej;
    if(!nofinessej) {
      throw new Error('USER_NO_ETAB');
    }
    return this.afs.collection<TransportCompanyFirestore>('legal_entities/' + nofinessej + '/transport_companies/')
      .doc(companyId).update(data);
  }
  async delCompany(companyId: string): Promise<void> {
    const nofinessej = (await this.legalEntity$.pipe(take(1)).toPromise())?.nofinessej;
    if(!nofinessej) {
      throw new Error('USER_NO_ETAB');
    }
    return this.afs.collection<TransportCompanyFirestore>('legal_entities/' + nofinessej + '/transport_companies/').doc(companyId).delete();
  }

  getSensorLocation(sensorNum) {
    return new Promise((resolve) => {
      this.afs.doc<kSensor>('tags/' + sensorNum).valueChanges().subscribe(value => {
        const data: GeoPoint = {
          lat: value.lastGps.value.latitude,
          lng: value.lastGps.value.longitude,
          acc: value.lastGps.value.accuracy
        };
        resolve(data);
      });
    });
  }

  //TODO : requete d'un vol airfrance
  calculeCourseStepTime(courseId: string) {
    return this.afs.doc<CourseFirestore>('courses/'+ courseId).valueChanges().pipe(pairwise(),filter(([prev, element]) => {
      // emit seulement quand il y a un changement d'étape.
      const stepAfter = prev.stepsTime.length - 1;
      const stepBefore = element.stepsTime.length - 1;
      return stepAfter !== stepBefore;
    }),map((arr) => arr[1]));
  }
  /**
   * Ajouter un utilisateur à une course en tenant compte de son état actif/inactif sur cette course.
   *
   * @param course
   * @param membersIds uid des membres à ajouter à la course
   */
  addMembersToCourse(course: Course, membersIds: Array<string>): Promise<void> {
    const data = this.membersToCourse(course, membersIds);
    return this.afs.collection('courses').doc(course.id).update(data);
  }
  batchWriteMembersToCourse(courses: Array<Course>, uid: string): Promise<void> {
    const userRef = this.afs.doc(`users/${uid}`).ref;
    const batch = this.afs.firestore.batch();
    for (const course of courses) {
      const courseRef = this.afs.collection('courses').doc(course.id).ref;
      const actionNoticeRef = this.afs.collection(`courses/${course.id}/messages`).doc().ref;
      batch.update(courseRef, this.membersToCourse(course, [uid]));
      batch.set(actionNoticeRef, this.actionNotice('JOIN', course.id, null));
    }
    return batch.commit();
  }
  membersToCourse(course: Course, membersIds: Array<string>):
    {members: Array<string>; membersStatus: Array<{ uid: string; isActive: boolean }>} {
    // le tableau contenant uniquement les membres actifs
    const members = [...course.members, ...membersIds];
    // le tableau contenant tous les membres (actif/inactif)
    const membersStatus = course.membersStatus;
    for (const newMemberId of membersIds) {
      const index = membersStatus.findIndex(u => u.uid === newMemberId);
      if (index === -1) {
        // si tout nouveau membre
        membersStatus.push({uid: newMemberId, isActive: true});
      } else {
        // si ancien membre ayant été retiré (membre inactif)
        membersStatus[index].isActive = true;
      }
    }
    return {members, membersStatus};
  }
  async getPDFDoc(docName: string) {
    const snapshot = await this.afs.doc<PDF>('pdfs/'+ docName).ref.get();
    return snapshot.data();
  }
  signUserCgu(cgu) {
    return this.afs.collection('users').doc(this.currentUid).update({cgu});
  }
  signUserDataUse(dataUse) {
    return this.afs.collection('users').doc(this.currentUid).update({dataUse});
  }
  signUserNewsletter() {
    return this.afs.collection('users').doc(this.currentUid).update({acceptNewsletter: true});
  }
  addSensorToCourse(sensorNum, courseId, userId) {
    const actionNoticeRef = this.afs.collection('/courses').doc(courseId).collection('/messages').doc().ref;
    const courseRef = this.afs.collection<CourseFirestore>('courses').doc(courseId).ref;
    const sensorRef = this.afs.collection('tags').doc(sensorNum).ref;
    const batch = this.afs.firestore.batch();
    // update courseEnCours : courseId to collection capteurs, champ "sensorNum"
    batch.update(sensorRef, {
      courseEnCours: courseId
    });
    // update capteur : sensorNum to collection courses, champ "capteur"
    batch.update(courseRef, {
      capteur: {
        ref: sensorRef,
        addDate: new Date()
      }
    });
    batch.set(actionNoticeRef, {
      date: new Date(),
      message: 'CAPTEUR',
      userId,
      type: 'ACTION_NOTICE'
    });

    return batch.commit();
  }

  addHubToCourse(hubId, courseId, userId) {
    const actionNoticeRef = this.afs.collection('/courses').doc(courseId).collection('/messages').doc().ref;
    const courseRef = this.afs.collection<CourseFirestore>('courses').doc(courseId).ref;
    const batch = this.afs.firestore.batch();
    const hubRef = this.afs.collection<Hub>('hubs').doc(hubId).ref;
    batch.update(hubRef, {
      courseEnCours: courseId
    });
    batch.update(courseRef, {
      hub: {
        ref: hubRef,
        addDate: new Date()
      }
    });
    batch.set(actionNoticeRef, {
      date: new Date(),
      message: 'HUB',
      userId,
      type: 'ACTION_NOTICE'
    });

    return batch.commit();
  }

  updateEntryTime(date: Date, courses: Array<Course>, userId: string): Promise<void> {
    const batch = this.afs.firestore.batch();
    for (const course of courses) {
      const actionNoticeRef = this.afs.collection('/courses').doc(course.id).collection('/messages').doc().ref;
      const courseRef = this.afs.collection<CourseFirestore>('courses').doc(course.id).ref;
      batch.update(courseRef, {
        entryTime: date
      });

      batch.set(actionNoticeRef, {
        date: new Date(),
        message: 'ENTRY_TIME_UPDATED',
        newEntryTime: date,
        userId,
        type: 'ACTION_NOTICE'
      });
    }
    return batch.commit();
  }

  listMyTransportCompanies(): Observable<Array<TransportCompany>> {
    const queryFn2 = ref => ref.orderBy('nom');
    return this.legalEntity$.pipe(take(1),
      switchMap(entity => entity ?
        this.afs.collection<TransportCompany>('legal_entities/' + entity.nofinessej + '/transport_companies', queryFn2)
          .snapshotChanges().pipe(map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const ref = a.payload.doc.ref;
            const id = a.payload.doc.id;
            return {id, ref, ...data} as TransportCompany;
          }))
        ) : of(undefined)));
  }

  getCoursesEtab(etabId: string, isEtabDonor: boolean): Observable<Course[]> {
    const query = ref => ref.where(isEtabDonor ? 'etabDonneur' : 'etabReceveur', '==', etabId);
    return this.afs.collection<CourseFirestore>('courses', query).snapshotChanges().pipe(map(actions => actions.map(a => {
      const data: CourseFirestore = a.payload.doc.data();
      const id = a.payload.doc.id;
      return mapCourseFromFirestore(data, id) as Course;
    })));
  }

  private init() {
    this.streamCurrentUser().subscribe(user => {
      this.currentUid = user ? user.uid : undefined;
      this.userReplaySubject.next(user);
    });
    this.streamCurrentLegalEntity().subscribe(etab => {
      this.legalEntityReplaySubject.next(etab);
    });
    this.streamAllLegalEntities().subscribe(etabs => {
      this.allLegalEntityReplaySubject.next(etabs);
    });
    this.streamAllFacilities().subscribe(facilities => {
      let merged = [];
      facilities.forEach(fac => {
        merged = merged.concat(fac);
      });
      this.facilitiesReplaySubject.next(merged);
    });

    this.streamCourses().subscribe(courses => {
      this.coursesReplaySubject.next(courses);
    });
    this.streamAllAlert().subscribe((alerts) => {
      let filterAlert;
      if (alerts) {
        filterAlert = alerts.filter((alert) => alert[0]);
      }
      this.alertsReplaySubject.next(filterAlert);
    });

    this.streamAllMessages().subscribe(messages => {
      this.messagesReplaySubject.next(messages);
    });
  }

  private streamCurrentUser(): Observable<User> {
    return this.afAuth.authState.pipe(switchMap(auth => auth ? this.afs.doc<UserFirestore>('users/' + auth.uid).snapshotChanges()
      .pipe(map(action => {
        const uid = action.payload.id;
        const data = action.payload.data();
        return {uid, ...data} as User;
      })) : of(undefined)));
  }

  private streamCourses() {
    return this.afAuth.authState.pipe(switchMap(auth => {
      if (auth) {
        return this.afs.doc<UserFirestore>(`/users/${auth.uid}`)
          .get().pipe(map(snap => ({uid: auth.uid, ...snap.data()} as User)),
            switchMap(user => {
              const coursesDonor$ = this.getCoursesEtab(user.etabId, true);
              const coursesRecipient$ = this.getCoursesEtab(user.etabId, false);
              return combineLatest([coursesDonor$, coursesRecipient$]).pipe(map(courses => {
                const recipientCourses = [];
                courses[1].forEach(course => {
                  if (!courses[0].find(c => c.id === course.id)) {
                    recipientCourses.push(course);
                  }
                });
                return courses[0].concat(recipientCourses);
              }));
            }),
            tap(res => this.storageService.deleteOldChatsViews(res.filter(c => c.attributes.includes('IN_PROGRESS')).map(c => c.id))));
      } else {
        return of([]);
      }
    }));
  }

  private streamCurrentLegalEntity(): Observable<LegalEntity> {
    return this.afAuth.authState.pipe(switchMap(auth => {
      if (auth) {
        return this.afs.doc<UserFirestore>(`/users/${auth.uid}`)
          .get().pipe(map(snap=> ({uid: auth.uid, ...snap.data()} as User)), switchMap(user => {
          if (user.etabId) {
            return this.afs.doc<LegalEntity>(`/legal_entities/${user.etabId}`).valueChanges();
          } else {
            throw new Error('USER_NO_ETAB');
          }
        }));
      } else {
        return of(undefined);
      }
    }));
  }

  private streamAllLegalEntities(): Observable<Array<LegalEntity>> {
    return this.afAuth.authState.pipe(switchMap(auth => {
      if (auth) {
        return this.afs.collection('legal_entities').snapshotChanges().pipe(map(actions => actions.map(a => {
          const data = a.payload.doc.data() as LegalEntity;
          const id = a.payload.doc.id;
          return {id, ... data} as LegalEntity;
        })));
      } else {
        return of(undefined);
      }
    }));
  }
  private streamAllFacilities(): Observable<Facility[]> {
    return this.allLegalEntity$.pipe(
      switchMap((entities) => {
        if (entities) {
          const fac = entities.map((entity) => this.afs.collection<Facility>('legal_entities/' + entity.nofinessej + '/facilities')
            .snapshotChanges().pipe(
              map((actions) => actions.map((a) => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                const isPartenaire = entity.personnel.length > 0;
                return {id, isPartenaire, ...data} as Facility;
              }))
            ));
          return combineLatest(fac);
        } else {
          return of([]);
        }
      }));
  };

  private streamAllMessages(): Observable<Message[][]> {
    return this.afAuth.authState.pipe(switchMap(auth => {
      if (auth) {
        return this.courses$.pipe(
          map(res => res.filter(c => c.attributes.includes('IN_PROGRESS') && c.members.includes(auth.uid))),
          switchMap(courses => {
            if (courses.length) {
              const queryFn = ref => ref.orderBy('date');
              const arrayRooms$ = courses.map(course => this.afs.collection<MessageFirestore>(`/courses/${course.id}/messages`, queryFn)
                .snapshotChanges(['added']).pipe(
                  map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const date = data.date.toDate();
                    return {id: a.payload.doc.id, ...data, date, courseId: a.payload.doc.ref.parent.parent.id};
                  }))
                ));
              return combineLatest(arrayRooms$);
            } else {
              return of([]);
            }
          }));
      } else {
        return of([]);
      }
    }));
  }

  private streamAllAlert(): Observable<Array<Array<Alert>>> {
    return this.afAuth.authState.pipe(switchMap(auth => {
      if (auth) {
        return this.courses$.pipe(
          switchMap((courses) => {
            if (courses.length) {
              const alerts = courses.map((course) => this.afs.collection<Alert>('courses/' + course.id + '/alerts')
                .snapshotChanges().pipe(
                  map((actions) => actions.map((a) => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return {id, ...data, courseId: a.payload.doc.ref.parent.parent.id} as Alert;
                  }))
                ));
              return combineLatest(alerts);
            } else {
              return of([]);
            }
          }));
      } else {
        return of([]);
      }
    }));
  }
}
