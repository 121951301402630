import * as CryptoJS from 'crypto-js';
import {CourseFirestore} from './models/firestore/CourseFirestore';
import {Course} from './models/Course';
import {ORGANS_BY_SPEC, SPEC_BY_ORGAN, SPECIALITES, ORGANES, FIXED_STEP_TIMES} from './constantes';
import * as dayjs from 'dayjs';

export const encryptData = (plainText: string) => {
  try {
    const password = 'STELLA';

    const passwordBytes = CryptoJS.enc.Utf16LE.parse(password);

    const sha1Hash = CryptoJS.SHA1(passwordBytes);

    const sha1HashToBase64 = sha1Hash.toString(CryptoJS.enc.Base64);

    // we are getting only the first 8 chars for actual key generation
    const sha1HashToBase64Short = sha1HashToBase64.substring(0, 8);

    const aesKey = CryptoJS.enc.Utf16.parse(sha1HashToBase64Short);
    return CryptoJS.AES.encrypt(plainText, aesKey, {
      iv: aesKey,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
  } catch (e) {
    console.log(e);
  }
};

export const decryptData = (cryptHex: string) => {
  const password = 'STELLA';

  const passwordBytes = CryptoJS.enc.Utf16LE.parse(password);

  const sha1Hash = CryptoJS.SHA1(passwordBytes);

  const sha1HashToBase64 = sha1Hash.toString(CryptoJS.enc.Base64);

  // we are getting only the first 8 chars for actual key generation
  const sha1HashToBase64Short = sha1HashToBase64.substring(0, 8);

  const aesKey = CryptoJS.enc.Utf16.parse(sha1HashToBase64Short);
  try {
    const bytes = CryptoJS.AES.decrypt(cryptHex, aesKey, {
      iv: aesKey
    });
    if (bytes.toString()) {
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return cryptHex;
  } catch (e) {
    console.log(e);
  }
};

export const mapCourseFromFirestore = (fsCourse: CourseFirestore, id: string): Course => {
  const decryptCristal = decryptData(fsCourse.numCristal);
  return {
    id,
    ...fsCourse,
    dateCreation: fsCourse.dateCreation.toDate(),
    capteur: fsCourse.capteur ?
      {
        ref: fsCourse.capteur.ref,
        addDate: fsCourse.capteur.addDate.toDate(),
        rmDate: fsCourse.capteur.rmDate ? fsCourse.capteur.rmDate.toDate() : null
      } : null,
    hub: fsCourse.hub ?
      {
        ref: fsCourse.hub.ref,
        addDate: fsCourse.hub.addDate.toDate(),
        rmDate: fsCourse.hub.rmDate ? fsCourse.hub.rmDate.toDate() : null
      } : null,
    entryTime: fsCourse.entryTime ? fsCourse.entryTime.toDate() : null,
    manualDepTime: fsCourse.manualDepTime ? fsCourse.manualDepTime.toDate() : null,
    cryptedCristal: fsCourse.numCristal,
    numCristal: decryptCristal,
    stepsTime: fsCourse.stepsTime.map(x => x.toDate())
  } as Course;
};

/**
 * Vérifier qu'un organe correspond à une spécialité donnée.
 *
 * @param spec
 * @param organ
 */
export const specialiteEqual = (spec: string, organ: string): boolean => {
  if (!organ) {
    console.warn('Checking specialite with undefined organ.');
    return false;
  } else if (SPECIALITES.includes(spec)) {
    return ORGANS_BY_SPEC[spec].includes(organ);
  } else {
    console.warn('La spécialité est erronée : ' + spec);
    return false;
  }
};

/**
 * Déterminer la spécialité correspondante à un organe.
 *
 * @param organ
 */
export const whichSpecialite = (organ: string): string => {
  if (ORGANES.includes(organ)) {
    return SPEC_BY_ORGAN[organ];
  } else {
    console.warn('L\'organe est erroné : ' + organ);
    return undefined;
  }
};

export const makeRandomStr = (lengthOfStr: number = 8,
                              possible: string = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890&%€µ%£$#@?§!') => {
  let text = '';
  for (let i = 0; i < lengthOfStr; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

/**
 * Pour après la saisie d'une date pour une étape de la course, corrige la date si la saisie est incohérente
 *
 * @param selectedDate date sélectionnée avec ion-datetime, date de l'étape suivante de la course
 * @param previousDate date de la dernière étape de la course
 */
export const parseDatetimeSelection = (selectedDate: Date, previousDate: Date) => {
  const previous = dayjs(previousDate);
  const now = dayjs().second(0);
  let next = dayjs(selectedDate);

  // Si la date sélectionnée est incohérente
  if (next.isAfter(now, 's')) {
    // Empêcher la selection d'une date subséquente à la date actuelle.
    next = now;
  } else if (next.isBefore(previous, 's')) {
    // Empêcher la selection d'une date antérieure à la précédente étape de la course.
    next = previous;
/*  }
  // bug Firestore, impossible d'ajouter une nouvelle date dans un array qui contient déjà la même date.
  if(next.isSame(previous, 's')) {
    // ajoute 1s à la nouvelle date pour contourner le problème.
    return next.add(1,'s').toDate();*/
  } else {
    return next.toDate();
  }
};

/**
 * Check in the chat if there is new messages which was not seen
 *
 * @param dateViewStorage date of the last view who was stored locally
 * @param dateMyLastMsg date of the last message of the current user
 * @param dateLastMsg date of the last message of all members of the group
 */
export const isChatSeen = (dateViewStorage: Date, dateMyLastMsg: Date, dateLastMsg: Date) => {
  let dateLastSeen;
  // On cherche la meilleure date pour le dernier vu
  if(dateViewStorage && dateMyLastMsg) {
    dateLastSeen = dayjs(dateViewStorage).isAfter(dateMyLastMsg) ? dateViewStorage : dateMyLastMsg;
  } else if(dateViewStorage) {
    dateLastSeen = dateViewStorage;
  } else if(dateMyLastMsg) {
    dateLastSeen = dateMyLastMsg;
  }
  if (!dateLastSeen) {
    // pas de date de vue enregistrée = messages non lus.
    return false;
  }
  // si la date du dernier message est après la date du dernier vu = messages non lus.
  return !dayjs(dateLastMsg).isAfter(dateLastSeen);
};

/**
 *
 *
 * Estimation de la durée de prélèvement en fonction du type d'organe
 *
 *
 *
 * @param isThorax si schéma d'explantation abdominal/thoracique dans le PMO
 * @param organ organe prélevé
 * @return durée du prélèvement en seconde
 */
export const getHarvestingDuration = (isThorax: boolean, organ: string): number => {
  if (!isThorax && SPEC_BY_ORGAN[organ] === 'CARDIAC_THORACIC') {
    // si jamais le PMO n'est pas marqué thoracique alors que l'organe l'est.
    isThorax = true;
  }
  // Temps d'explantation pour chaque organe
  const TIME_BY_ORGAN: {[_: string]: number} = {
    HEART: 900, // 15 min
    LUNG: 2700, // 45 min
    LIVER: 900, // 15 min
    PANCREAS: 1800, // 30 min
    INTESTINE: 1800, // 30 min
    KIDNEY: 300, // 5 min
    PANCREATIC_ISLETS: 600, // 10 min
  };
  const EXPLANT_TIME_ABDO: {[_: string]: number} = {
    get LIVER() {
      return TIME_BY_ORGAN.LIVER;
    },
    get L_LIVER() {
      return TIME_BY_ORGAN.LIVER;
    },
    get R_LIVER() {
      return TIME_BY_ORGAN.LIVER;
    },
    get PANCREAS() {
      return this.LIVER + TIME_BY_ORGAN.PANCREAS;
    },
    get INTESTINE() {
      return this.PANCREAS + TIME_BY_ORGAN.INTESTINE;
    },
    get KIDNEY() {
      return this.INTESTINE + TIME_BY_ORGAN.KIDNEY;
    },
    get L_KIDNEY() {
      return this.INTESTINE + TIME_BY_ORGAN.KIDNEY;
    },
    get R_KIDNEY() {
      return this.INTESTINE + TIME_BY_ORGAN.KIDNEY;
    },
    get PANCREATIC_ISLETS() {
      return this.KIDNEY + TIME_BY_ORGAN.PANCREATIC_ISLETS;
    },
  };
  const EXPLANT_TIME_ABDO_THORAX: {[_: string]: number} = {
    get HEART() {
      return TIME_BY_ORGAN.HEART;
    },
    get LUNG() {
      return this.HEART + TIME_BY_ORGAN.LUNG;
    },
    get L_LUNG() {
      return this.HEART + TIME_BY_ORGAN.LUNG;
    },
    get R_LUNG() {
      return this.HEART + TIME_BY_ORGAN.LUNG;
    },
    get LIVER() {
      return this.LUNG + TIME_BY_ORGAN.LIVER;
    },
    get L_LIVER() {
      return this.LUNG + TIME_BY_ORGAN.LIVER;
    },
    get R_LIVER() {
      return this.LUNG + TIME_BY_ORGAN.LIVER;
    },
    get PANCREAS() {
      return this.LIVER + TIME_BY_ORGAN.PANCREAS;
    },
    get INTESTINE() {
      return this.PANCREAS + TIME_BY_ORGAN.INTESTINE;
    },
    get KIDNEY() {
      return this.INTESTINE + TIME_BY_ORGAN.KIDNEY;
    },
    get L_KIDNEY() {
      return this.INTESTINE + TIME_BY_ORGAN.KIDNEY;
    },
    get R_KIDNEY() {
      return this.INTESTINE + TIME_BY_ORGAN.KIDNEY;
    },
    get PANCREATIC_ISLETS() {
      return this.KIDNEY + TIME_BY_ORGAN.PANCREATIC_ISLETS;
    },
  };
  return isThorax ? EXPLANT_TIME_ABDO_THORAX[organ] : EXPLANT_TIME_ABDO[organ];
};

export const getEstimatedDepartureTime = (typeGreffe: string, isThorax: boolean, entryTime: Date,
                                          stepsTime?: Array<Date>) => {
  const step = stepsTime ? stepsTime.length-1 : 0;
  const harvestingDuration = getHarvestingDuration(isThorax, typeGreffe);
  let addedTime: number;
  let refDate: Date;
  switch (step) {
    case 0:
      refDate = entryTime;
      addedTime = harvestingDuration + FIXED_STEP_TIMES.preclamp + FIXED_STEP_TIMES.departure;
      break;
    case 1:
      refDate = stepsTime[1];
      addedTime = harvestingDuration + FIXED_STEP_TIMES.preclamp + FIXED_STEP_TIMES.departure;
      break;
    case 2:
      refDate = stepsTime[2];
      addedTime = harvestingDuration + FIXED_STEP_TIMES.departure;
      break;
    case 3:
      refDate = stepsTime[3];
      addedTime = FIXED_STEP_TIMES.departure;
      break;
    default:
      refDate = stepsTime[4];
      addedTime = 0;
  }
  const departure = dayjs(refDate).add(addedTime, 's');
/*  // l'heure de départ estimé ne doit jamais être dans le passé
  if(step < 4 && departure.isBefore(dayjs())) {
    departure = dayjs();
  }*/
  return departure.toDate();
};

export const customTimeFormat = (date: Date | dayjs.Dayjs): string => {
  date = dayjs(date);
  if (date.isValid()) {
    let timeString = date.format('LT');
    if (date.isBefore(dayjs(), 'day')) {
      const today = dayjs().set('hour', 0).set('minute', 0).set('second', 0).millisecond(0);
      // TODO T-minus x days en anglais
      timeString += ' J\u2011' + Math.ceil(today.diff(date, 'day', true));
    } else if (date.isAfter(dayjs(), 'day')) {
      const today = dayjs().set('hour', 23).set('minute', 59).set('second', 59).millisecond(999);
      timeString += ' J+' + Math.ceil(date.diff(today, 'day', true));
    }
    return timeString;
  } else {
    return '--:--';
  }
};
