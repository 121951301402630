import {Injectable} from '@angular/core';

import {Storage} from '@ionic/storage-angular';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private chatsViewsObserver: BehaviorSubject<{ [_: string]: Date }> = new BehaviorSubject<{ [_: string]: Date }>({});

  private _storage: Storage | null = null;
  private readonly initPromise: Promise<void>;
  constructor(private storage: Storage) {
    this.initPromise = this.init();
  }
  isReady() {
    return Promise.resolve(this.initPromise);
  }
  public chatsViewsValueChanges(): Observable<{ [_: string]: Date }> {
    return this.chatsViewsObserver.asObservable();
  }
  public set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  public get(key: string) {
    return this._storage?.get(key);
  }

  public remove(key: string) {
    return this._storage.remove(key);
  }

  /**
   * Update la date et heure à laquelle l'utilisateur à vu la convertation d'une course pour la dernière fois
   */
  async updateChatView(idCourse: string): Promise<void> {
    const date = new Date();
    let chatsViews: { [_: string]: Date } = await this._storage.get('coursesChatsViews');
    if (!chatsViews) {
      chatsViews = {};
    }
    chatsViews[idCourse] = date;

    return this._storage.set('coursesChatsViews', chatsViews)
      .then(()=> this.chatsViewsObserver.next(chatsViews));
  }

  /**
   * Get la date et heure à laquelle l'utilisateur à vu la convertation d'une course pour la dernière fois
   */
  getChatView(idCourse: string): Promise<Date> {
    return this._storage.get('coursesChatsViews').then((chatsViews: { [_: string]: Date }) => {
      if (!chatsViews) {
        return undefined;
      }
      if (!chatsViews[idCourse]) {
        return undefined;
      }
      return chatsViews[idCourse];
    });
  }
  /**
   * Remove courses views from storage when it is archived or canceled
   *
   * @param coursesIds
   */
  async deleteOldChatsViews(coursesIds: Array<string>) {
    await this.isReady();
    let chatsViews: { [_: string]: Date } = await this._storage.get('coursesChatsViews');
    if (chatsViews) {
      // pour chaque vue, vérifie si la course est toujours en cours
      for (const courseId of Object.keys(chatsViews)) {
        // si non, suppression de l'attribut correspondant
        if (!coursesIds.includes(courseId)) {
          delete chatsViews[courseId];
        }
      }
    } else {
      chatsViews = {};
    }
    return this._storage.set('coursesChatsViews', chatsViews)
      .then(()=> this.chatsViewsObserver.next(chatsViews));
  }

  /**
   * Get la date et heure à laquelle l'utilisateur à vu la modification des poches d'une course pour la dernière fois
   */
  getSelectedPocheView(idCourse: string): Promise<Date> {
    return this._storage.get('selectedPocheViews').then(selectedPocheViews => {
      if (!selectedPocheViews) {
        return undefined;
      }
      if (!selectedPocheViews[idCourse]) {
        return undefined;
      }
      return selectedPocheViews[idCourse];
    });
  }

  /**
   * Update la date et heure à laquelle l'utilisateur à vu la la modification des poches d'une course pour la dernière fois
   */
  async updateSelectedPocheView(idCourse: string): Promise<void> {
    const date = new Date();
    let selectedPocheViews: { [_: string]: Date } = await this._storage?.get('selectedPocheViews');
    if (!selectedPocheViews) {
      selectedPocheViews = {};
    }
    selectedPocheViews[idCourse] = date;
    return this._storage?.set('selectedPocheViews', selectedPocheViews);
  }
  private async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    this._storage = await this.storage.create();
    return Promise.resolve();
  }

}
