export const PARCOURS_CLASSIC: Array<string> = [
  'suivi.courseCreated',         // 0
  'suivi.enteringRoom',         // 1
  'suivi.clampage',             // 2
  'suivi.explantation',         // 3
  'suivi.departEtabDonneur',    // 4
  'suivi.arriveeEtabReceveur',  // 5
  'suivi.declampage',           // 6
  'suivi.suivi7'
];

export const PARCOURS_CLASSIC_ABREGE: Array<string> = [
  'suivi.courseCreatedAbrégé',         // 0
  'suivi.enteringRoomAbrégé',         // 1
  'suivi.clampageAbrégé',             // 2
  'suivi.explantationAbrégé',         // 3
  'suivi.departEtabDonneurAbrégé',    // 4
  'suivi.arriveeEtabReceveurAbrégé',  // 5
  'suivi.declampageAbrégé',           // 6
  'suivi.suivi7Abrégé'
];
export const PARCOURS_ABM: Array<string> = [
  'suivi.courseLancee',         // 0
  'suivi.enteringRoom',         // 1
  'suivi.clampage',             // 2
  'suivi.explantation',         // 3
  'suivi.departEtabDonneur',    // 4
  'suivi.arriveeAbm'            // 5
];
export const PARCOURS_ABM_ABREGE: Array<string> = [
  'suivi.courseCreatedAbrégé',         // 0
  'suivi.enteringRoomAbrégé',         // 1
  'suivi.clampageAbrégé',             // 1
  'suivi.explantationAbrégé',         // 2
  'suivi.departEtabDonneurAbrégé',    // 3
  'suivi.arriveeAbmAbrégé'            // 4
];
export const PARCOURS_LABO: Array<string> = [
  'suivi.courseLancee',           // 0
  'suivi.prelevementHLA',         // 1
  'suivi.departEtabDonneur',     // 2
  'suivi.arriveeLabo',            // 3
];
export const PARCOURS_LABO_ABREGE: Array<string> = [
  'suivi.courseCreatedAbrégé',           // 0
  'suivi.prelevementHLAAbrégé',         // 1
  'suivi.departEtabDonneurAbrégé',     // 2
  'suivi.arriveeLaboAbrégé',            // 3

];
export const MACHINES_BY_ORGAN = {
  HEART: ['Organ Care System Heart'],
  LUNG: ['Organ Care System Lung', 'Lung Assist', 'XPS', 'XVIVO LS',],
  L_LUNG: ['Organ Care System Lung', 'Lung Assist', 'XPS', 'XVIVO LS',],
  R_LUNG: ['Organ Care System Lung', 'Lung Assist', 'XPS', 'XVIVO LS',],
  KIDNEY: ['Waves', 'RM4', 'Kidney Assist Transport', 'Kidney Assist', 'LifePort Kidney Transporter', 'PerKidney'],
  L_KIDNEY: ['Waves', 'RM4', 'Kidney Assist Transport', 'Kidney Assist', 'LifePort Kidney Transporter', 'PerKidney'],
  R_KIDNEY: ['Waves', 'RM4', 'Kidney Assist Transport', 'Kidney Assist', 'LifePort Kidney Transporter', 'PerKidney'],
  LIVER: ['Organ Care System Liver', 'LifePort Liver Transporter', 'PerLiver', 'Liver Assist', 'Metra'],
  L_LIVER: ['Organ Care System Liver', 'LifePort Liver Transporter', 'PerLiver', 'Liver Assist', 'Metra'],
  R_LIVER: ['Organ Care System Liver', 'LifePort Liver Transporter', 'PerLiver', 'Liver Assist', 'Metra'],
  PANCREAS: [],
  INTESTINE: [],
  PANCREATIC_ISLETS: []
};
export const ORGANES: Array<string> = [
  //'KIDNEY',
  'L_KIDNEY',
  'R_KIDNEY',
  'HEART', // traduction : 'organ.HEART'
  'LUNG',
  'L_LUNG',
  'R_LUNG',
  'LIVER',
  'L_LIVER',
  'R_LIVER',
  'PANCREAS',
  'INTESTINE',
  'PANCREATIC_ISLETS'
];

export const ORGANE = [
  {name:'HEART', imagesrc:'assets/icon/HEART.svg'},
  {name:'LUNG', imagesrc:'assets/icon/LUNG.svg'},
  {name:'L_LUNG', imagesrc:'assets/icon/L_LUNG.svg'},
  {name:'R_LUNG', imagesrc:'assets/icon/R_LUNG.svg'},
  //Retrait des reinS pas encore prévu correctement niveau code
  //{name:'KIDNEY', imagesrc:'assets/icon/KIDNEY.svg'},
  {name:'L_KIDNEY', imagesrc:'assets/icon/L_KIDNEY.svg'},
  {name:'R_KIDNEY', imagesrc:'assets/icon/R_KIDNEY.svg'},
  {name:'LIVER', imagesrc:'assets/icon/LIVER.svg'},
  {name:'L_LIVER', imagesrc:'assets/icon/L_LIVER.svg'},
  {name:'R_LIVER', imagesrc:'assets/icon/R_LIVER.svg'},
  {name:'PANCREAS', imagesrc:'assets/icon/PANCREAS.svg'},
  {name:'INTESTINE', imagesrc:'assets/icon/INTESTINE.svg'},
  {name:'PANCREATIC_ISLETS', imagesrc:'assets/icon/PANCREAS.svg'},
];

export const ROLES: Array<string> = [
  'COOR_GREFFE',
  'COOR_PRELEVEMENT',
  'CHIR'
];
export const SPECIALITES: Array<string> = [
  'UROLOGIST_VASCULAR',
  'CARDIAC_THORACIC',
  'DIGESTIVE'
];

export const ORGANS_BY_SPEC = {
  UROLOGIST_VASCULAR: [/*'KIDNEY',*/ 'L_KIDNEY', 'R_KIDNEY'],
  DIGESTIVE: ['LIVER', 'L_LIVER', 'R_LIVER', 'INTESTINE', 'PANCREAS', 'PANCREATIC_ISLETS'],
  CARDIAC_THORACIC: ['HEART', 'LUNG', 'L_LUNG', 'R_LUNG']
};
export const ORGANS_BY_SPEC_IMAGE = {
  UROLOGIST_VASCULAR: [
    // {name: 'KIDNEY', imagesrc: 'assets/icon/KIDNEY.svg'},
    {name: 'L_KIDNEY', imagesrc: 'assets/icon/L_KIDNEY.svg'},
    {name: 'R_KIDNEY', imagesrc: 'assets/icon/R_KIDNEY.svg'}
  ],
  DIGESTIVE: [
    {name: 'LIVER', imagesrc: 'assets/icon/LIVER.svg'},
    {name: 'L_LIVER', imagesrc: 'assets/icon/L_LIVER.svg'},
    {name: 'R_LIVER', imagesrc: 'assets/icon/R_LIVER.svg'},
    {name: 'INTESTINE', imagesrc: 'assets/icon/INTESTINE.svg'},
    {name: 'PANCREAS', imagesrc: 'assets/icon/PANCREAS.svg'},
    {name: 'PANCREATIC_ISLETS', imagesrc: 'assets/icon/PANCREAS.svg'}],
  CARDIAC_THORACIC: [
    {name: 'HEART', imagesrc: 'assets/icon/HEART.svg'},
    {name: 'LUNG', imagesrc: 'assets/icon/LUNG.svg'},
    {name: 'L_LUNG', imagesrc: 'assets/icon/L_LUNG.svg'},
    {name: 'R_LUNG', imagesrc: 'assets/icon/R_LUNG.svg'},
  ]
};

export const SPEC_BY_ORGAN: {[_: string]: string} = {
  HEART: 'CARDIAC_THORACIC',
  LUNG: 'CARDIAC_THORACIC',
  L_LUNG: 'CARDIAC_THORACIC',
  R_LUNG: 'CARDIAC_THORACIC',
  KIDNEY: 'UROLOGIST_VASCULAR',
  L_KIDNEY: 'UROLOGIST_VASCULAR',
  R_KIDNEY: 'UROLOGIST_VASCULAR',
  LIVER: 'DIGESTIVE',
  L_LIVER: 'DIGESTIVE',
  R_LIVER: 'DIGESTIVE',
  PANCREAS: 'DIGESTIVE',
  INTESTINE: 'DIGESTIVE',
  PANCREATIC_ISLETS: 'DIGESTIVE'
};

export const FIXED_STEP_TIMES: {[_: string]: number} = {
  preclamp: 18000,
  departure: 600,
  incision : 3600
};

export const CHAT_AUTHORIZED_FILES: Array<string> = [
  'xls',
  'xlsx',
  'pdf'
];
