<ion-app class="scanner-hide">
  <ion-split-pane contentId="my-content" when="sm">
    <ion-menu type="overlay" class="menu-bar" contentId="my-content" menuId="first" swipeGesture="false"
              disabled="true" style="overflow: hidden">
      <ion-header>
        <ion-toolbar class="ion-text-center" style="--background: var(--ion-color-tertiary)">
          <img alt="eGraft" class="img-egraft" src="../../../assets/img/Picto-egraft.png" (click)="navigate('/pmo')">
        </ion-toolbar>
      </ion-header>
      <ion-content class="nav-bar-vertical-background" scroll-y="false">
        <ng-container *ngIf="user?.nom && etabNom">
          <ion-list class="bg-transparent ion-text-center">
            <ion-menu-toggle auto-hide="false" *ngFor="let p of pagesList; let i = index">
              <div class="container-icon-label" [routerLink]="p.url" routerLinkActive="active" routerDirection="root"
                   [title]="p.title | translate" [class.disabled-link]="p.disabled" (click)="move()">
                <ion-icon slot="start" class="icon vert-nav-bar"
                          [ngClass]="{'create-course' : p.url === '/course-create'}" [name]="p.icon"></ion-icon>
                <span *ngIf="p.title === 'pmo.CHATS' && newMsgs " class="blue_dot"></span>
              </div>
            </ion-menu-toggle>
          </ion-list>
        </ng-container>

        <!--TODO : Réactiver quand un nouveau tuto sera fait.-->
        <!--        <div class="btnbottom">
                  <div class="centred-btn-container container-icon-label icon-bottom">
                    <ion-icon slot="start" class="icon info" name="information-circle-outline"
                              (click)="goToTuto()"></ion-icon>
                  </div>
                </div>-->
      </ion-content>
    </ion-menu>

    <nav *ngIf="showBottomBar" class="bottom-nav" [ngClass]="{'disabled': findPage(pagesList)}">
      <ng-container *ngIf="user?.nom && etabNom">
        <ng-container *ngFor="let p of pagesList; let i = index">
          <div class="bottom-nav-item">
            <div class="bottom-nav-link" [class.disabled-link]="p.disabled" [routerLink]="p.url" routerDirection="root"
                 routerLinkActive="active">
              <ion-icon slot="start" class="icon" [name]="p.icon"
                        [ngClass]="{'create-course' : p.url === '/course-create', 'chat' : p.title === 'pmo.CHATS' && newMsgs }"></ion-icon>
              <span *ngIf="p.title === 'pmo.CHATS' && newMsgs" class="blue_dot2"></span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </nav>

    <ion-router-outlet id="my-content"></ion-router-outlet>
  </ion-split-pane>

</ion-app>
<div class="scanner-ui">
  <ion-button (click)="stopScan()" class="scanner-stop">{{'stop' | translate}}</ion-button>
</div>
