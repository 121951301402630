import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {UserFirestore} from './models/firestore/UserFirestore';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AuthService} from './services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(public afs: AngularFirestore,
                public authService: AuthService,
                public router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return new Promise(async (resolve, reject) => {
            try {
                const user = await this.authService.getAuthUser();
                const emailVerified = user?.emailVerified;
                const currentPage = route.routeConfig.path;
                // l'utilisateur doit être authentifié
              if (!(user && emailVerified)) {
                reject('No user logged or did not validate email');
                this.router.navigateByUrl('/login');
              } else {
                Promise.all([
                  this.afs.doc<UserFirestore>('users/' + user.uid).ref.get(),
                  this.afs.doc('pdfs/egCgu').ref.get(),
                  this.afs.doc('pdfs/egLegalNotice').ref.get()
                ]).then(([userSnapshot, cguSnapshot, pdSnapshot]) => {
                  const userData = userSnapshot.data();
                  // l'utilisateur doit avoir accepté les CGU.
                  if (userData.cgu?.version !== cguSnapshot.get('version') || userData.dataUse?.version !== pdSnapshot.get('version')) {
                    reject('You need to accept latest ToE');
                    this.router.navigateByUrl('/cgu');
                  } else {
                    // Les informations de l'utilisateur doivent être complète.
                    if (!userData.nom || !userData.prenom) {
                      reject('Need informations');
                      this.router.navigateByUrl('gestion/mon-compte');
                    }
                    resolve(true);
                  }
                });
              }
            } catch (error) {
                reject(error);
            }
        });
    }
}
