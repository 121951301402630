import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicSelectableModule } from 'ionic-selectable';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { NotificationGestionComponent } from './shared/components/notification-gestion/notification-gestion.component';
import { HistoryNotificationComponent } from './shared/components/history-notification/history-notification.component';
import {
  NotificationParamPopoverComponent
} from './shared/components/notification-gestion/notification-param-popover/notification-param-popover.component';
import { AuthService } from './shared/services/auth.service';
import { GlobalService } from './shared/services/global.service';
import { StorageService } from './shared/services/storage.service';
import { NgswUpdateService } from './shared/services/ngsw-update.service';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire/compat';
export const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    AppComponent,
    NotificationGestionComponent,
    HistoryNotificationComponent,
    NotificationParamPopoverComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    IonicSelectableModule,
    NgApexchartsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    // AngularFirePerformanceModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.platform === 'pwa' && environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: REGION, useValue: 'europe-west1' },
    AuthService,
    GlobalService,
    StorageService,
    NgswUpdateService,
    CallNumber,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
