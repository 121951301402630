import {AlertController, AlertInput, Platform, ToastController} from '@ionic/angular';
import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../models/User';
import {CallNumber} from '@awesome-cordova-plugins/call-number/ngx';
import {Clipboard} from '@capacitor/clipboard';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toast: HTMLIonToastElement;
  constructor(private toastCtrl: ToastController, private alertController: AlertController,
              public router: Router, private translate: TranslateService, public platform: Platform,
              public callNumber: CallNumber) { }

  async showToast(message: string, color: string, duration: number, icon: string = null): Promise<void> {
    if (this.toast) {
      await this.toast.dismiss();
    }
    this.toast = await this.toastCtrl.create({
      message,
      duration,
      position: 'bottom',
      color,
      icon,
      buttons:[
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
          }}
      ]
    });
    return this.toast.present();
  }

  async invitToast() {
    const toast = await this.toastCtrl.create({
      message: this.translate.instant('alerte.addUser'),
      duration: 2000
    });
    await toast.present();
  }

  async trajetUncomplet() {
    const toast = await this.toastCtrl.create({
      message: this.translate.instant('erreur.UNCOMPLETE_TRAJ'),
      duration: 5000,
      icon: 'warning-outline'
    });

    await toast.present();
  }

  async showAlert(header: string, message: string) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK']
    });
    await alert.present();
    return alert.onDidDismiss();
  }

  async showPush(message: string, duration: number, pageLanding: string, idPage: string): Promise<void> {
    this.toast = await this.toastCtrl.create({
      message,
      duration,
      position: 'top',
      color: 'primary',
      buttons:[
        {
          text: 'Go',
          role: 'cancel',
          handler: () => {
            this.router.navigate([pageLanding, idPage]);
            this.toast.dismiss();
          }}
      ]
    });
    this.toast.present();
  }

  async presentCallAlertRadio(user: User) {

    const isCapacitor = this.platform.is('capacitor');
    let inputs: AlertInput[];
    let message: string;
    if (user.telephone2) {
      message = isCapacitor ? this.translate.instant('alerte.CHOOSE_CALL_NUMBER') + '&#32;&#32;<ion-icon name="call-outline"></ion-icon>'
        : this.translate.instant('alerte.CHOOSE_COPY_NUMBER') + '&#32;&#32;<ion-icon name="copy-outline"></ion-icon>';
      inputs = [
        {
          name: 'telephone1',
          type: 'radio',
          label: user.telephone1,
          value: user.telephone1,
          checked: true
        },
        {
          name: 'telephone2',
          type: 'radio',
          label: user.telephone2,
          value: user.telephone2
        }
      ];
    } else {
      inputs = [
        {
          name: 'telephone1',
          type: 'radio',
          label: user.telephone1,
          value: user.telephone1,
          checked: true
        }];
      message = isCapacitor ? this.translate.instant('alerte.CALL_NUMBER') + '&#32;&#32;<ion-icon name="call-outline"></ion-icon>' :
        this.translate.instant('alerte.COPY_NUMBER') + '&#32;&#32;<ion-icon name="copy-outline"></ion-icon>';
    }
    const alert = await this.alertController.create({
      header: this.translate.instant('alerte.PHONE_CALL'),
      subHeader: this.translate.instant('alerte.CONTACT') + user.prenom + ' ' + user.nom,
      message,
      inputs,
      buttons: [
        {
          text: this.translate.instant('ANNULER'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant(isCapacitor ? 'alerte.CALL' : 'alerte.COPY'),
          handler: (num) => {
            if(isCapacitor) {
              this.callNow(num);
            } else {
              this.copyClipboard(num);
            }
          }
        }
      ]
    });
    await alert.present();
  }


  async presentAlertPermissionDenied() {
    const alert = await this.alertController.create({
      header: this.translate.instant('alerte.erreur'),
      message: this.translate.instant('alerte.PERMISSION_DENIED'),
      buttons: ['OK']
    });
    await alert.present();
  }

  async presentAlertPermissionDeniedMobile() {
    const alert = await this.alertController.create({
      header: this.translate.instant('alerte.erreur'),
      message: this.translate.instant('alerte.PERMISSION_DENIED_MOBILE'),
      buttons: ['OK']
    });
    await alert.present();
  }

  private async copyClipboard(string: string) {
    if (this.platform.is('capacitor')) {
      await Clipboard.write({
        string
      });
      return this.showToast(this.translate.instant('CopiedClipboard'), 'medium', 2000);
    } else if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(string);
        return this.showToast(this.translate.instant('CopiedClipboard'), 'medium', 2000);
      } catch (err) {}
    }
  }

  private async callNow(tel: string) {
    if (this.platform.is('capacitor')) {
      return this.callNumber.callNumber(tel, true);
    } else {
      try {
        await navigator.clipboard.writeText(tel);
      } catch (err) {}
      return this.showToast(this.translate.instant('CopiedClipboard'), 'medium', 2000);
    }
  }
}
