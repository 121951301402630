import {Injectable} from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import {environment} from '../../../environments/environment';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Router} from '@angular/router';
import {SwPush, SwUpdate} from '@angular/service-worker';
import {Notification} from '../models/Notification';
import {map, switchMap, tap} from 'rxjs/operators';
import {GlobalService} from './global.service';
import {combineLatest, of, Subscription} from 'rxjs';
import {StorageService} from './storage.service';
import {User} from '../models/User';
import {Course} from '../models/Course';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public pageLanding: string;
  public idPage: string;
  public numCristal: string;
  public notificationsList: Array<any> = [];
  public origin: string;
  public userId: string;
  public isGranted: boolean;
  subscription = new Subscription();

  constructor(public afs: AngularFirestore,
              public storageService: StorageService,
              public router: Router,
              public updates: SwUpdate,
              public push: SwPush,
              public globalService: GlobalService) {
    this.push.messages.subscribe((msg: any) => {
      this.notificationsList.push(msg.notification);
      //this.pageLanding = msg.data.landing_page;
      this.numCristal = msg.data.numCristal;
      this.idPage = msg.data.id;
    });
    this.push.notificationClicks.subscribe(click => {
      this.router.navigate([this.pageLanding, this.numCristal, this.idPage]);
    });
  }

  getNotificationsList(userId) {
    const queryFn = ref => ref.orderBy('isSeen', 'asc').orderBy('date', 'desc');
    return this.afs.collection<Notification>(`/users/${userId}/notifications`, queryFn)
      .snapshotChanges().pipe(map(actions => actions.map(a => {
      const data = a.payload.doc.data();
      const id = a.payload.doc.id;
      return {id, ...data};
    })));
    // return this.afs.collection<Notification>(`/users/${userId}/notifications`, queryFn).snapshotChanges().pipe(
    //   switchMap((notifs) => {
    //     const val = notifs.map((notif) =>
    //       this.afs.doc<Course>(`/courses/${notif.payload.doc.data().idCourse}`).snapshotChanges().pipe(map((actions) => {
    //         console.log('SADGE COURSE', actions.payload.data());
    //         const data = notif.payload.doc.data();
    //         const id = notif.payload.doc.id;
    //         const temp = data.temp;
    //         const num = data.numCristal;
    //         if(actions.payload.data()){
    //           const organ = actions.payload.data().typeGreffe;
    //           return {id, ...data, temp, num, organ};
    //         } else {
    //           return {id, ...data, temp, num, organ: null};
    //         }
    //       })));
    //     if (val.length === 0) {
    //       console.log('NO VALUES', val);
    //       return of(null);
    //     } else {
    //       console.log('VALUES', val);
    //       return combineLatest(val);
    //     }
    //   }));
  }

  enableNotif(userId) {
    Notification.requestPermission().then((result) => {
      if (result === 'granted') {
        this.isGranted = true;
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready.then((registration) => {
            const messaging = firebase.messaging();
            messaging.getToken(
              {
                vapidKey: environment.vapidKey,
                serviceWorkerRegistration: registration
              })
              .then((refreshedToken: string) => {
                this.storageService.set('registrationToken', refreshedToken);
                combineLatest([this.isTokenTaken(refreshedToken), this.globalService.user$]).subscribe(value => {
                  value[0].forEach(user => {
                    if (user.id !== value[1].uid) {
                      this.deleteToken(user.id, refreshedToken);
                    }
                  });
                });
                this.afs.doc(`/users/${userId}`).update({
                  registrationTokens: firebase.firestore.FieldValue.arrayUnion(refreshedToken)
                });
              }).catch((err) => {
              console.error(err);
            });
          });
        }
      } else {
        this.isGranted = false;
      }
    });
  }

  async deleteToken(userId, tokenId) {
    await this.afs.doc(`/users/${userId}`).update({
      registrationTokens: firebase.firestore.FieldValue.arrayRemove(tokenId)
    });
  }

  isTokenTaken(token) {
    return this.afs.collection('users', ref => ref.where('registrationTokens', 'array-contains', token)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as User;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }
}
