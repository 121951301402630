import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import {environment} from '../../../environments/environment';
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from '@capacitor/push-notifications';
import {StorageService} from './storage.service';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {ToastService} from './toast.service';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {User} from '../models/User';
import {GlobalService} from './global.service';
import {combineLatest} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FcmService {
isGranted: boolean;
  constructor(public storageService: StorageService, public afs: AngularFirestore,
              public toastService: ToastService, public router: Router,
              public globalService: GlobalService) { }
  async enableNotif(userId) {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        PushNotifications.register();
        this.isGranted = true;
      } else {
        this.isGranted = false;
        console.error('error register TOken');
      }
    });
    PushNotifications.addListener('registration',
      (token: Token) => {
        this.storageService.set('registrationToken', token.value);
        combineLatest([this.isTokenTaken(token.value), this.globalService.user$]).subscribe(value => {
          value[0].forEach(user => {
            if (user.id !== value[1].uid) {
              this.deleteToken(user.id, token.value);
            }
          });
        });
        this.afs.doc(`/users/${userId}`).update({
          registrationTokens: firebase.firestore.FieldValue.arrayUnion(token.value)
        });
      });
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
      this.toastService.showPush(notification.body, 10000, notification.data.landing_page, notification.data.id);
      }
    );
    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        this.router.navigate(
          [notification.notification.data.landing_page, notification.notification.data.numCristal, notification.notification.data.id]);
      }
    );
  }

  async deleteToken(userId, tokenId) {
    await this.afs.doc(`/users/${userId}`).update({
      registrationTokens: firebase.firestore.FieldValue.arrayRemove(tokenId)
    });
  }

  isTokenTaken(token) {
    return this.afs.collection('users', ref => ref.where('registrationTokens', 'array-contains', token)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as User;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }
}
