import {Component, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Subscription} from 'rxjs';
import {User} from './shared/models/User';
import {
  AlertController,
  MenuController, ModalController,
  NavController,
  Platform,
  PopoverController
} from '@ionic/angular';
import {GlobalService} from './shared/services/global.service';
import {AuthService} from './shared/services/auth.service';
import {NotificationService} from './shared/services/notification.service';
import {StorageService} from './shared/services/storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as dayjs from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/fr';
import {FcmService} from './shared/services/fcm.service';
import {BarcodeScanner} from '@capacitor-community/barcode-scanner';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {NgswUpdateService} from './shared/services/ngsw-update.service';
import {Message} from './shared/models/Message';
import {isChatSeen} from './shared/utils';

interface AppPage {
  title: string;
  url: string;
  icon: string;
  disabled: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {
  appPagesCoorPrelev: Array<AppPage> = [
    {title: 'pmo.ONGOING_PMO', url: '/pmo', icon: 'stopwatch-outline', disabled: false},
    {title: 'pmo.ARCHIVES', url: '/pmo-archive', icon: 'archive-outline', disabled: false},
    {title: 'pmo.NEW_PMO', url: '/course-create', icon: 'add-circle-outline', disabled: false},
    {title: 'pmo.CHATS', url: '/course-chat-list', icon: 'chatbubble-ellipses-outline', disabled: false},
    {title: 'pmo.PARAMETRES', url: '/gestion', icon: 'settings-outline', disabled: false},
  ];
  pagesList: Array<AppPage>;
  subscription: Subscription = new Subscription();
  authSubscription: Subscription = new Subscription();

  user: User;
  uid: string;
  etabNom: string;
  isAuthenticated = false;
  appInit = true;
  etabLicensed: boolean;
  userRole: string;
  showBottomBar = false;
  newMsgs: boolean;
  initialisedOffline = false;

  constructor(
    private route: ActivatedRoute,
    public navCtrl: NavController,
    public globalService: GlobalService,
    public translate: TranslateService,
    public authService: AuthService,
    public menu: MenuController,
    public alertController: AlertController,
    public popoverController: PopoverController,
    public notificationService: NotificationService,
    public storageService: StorageService,
    private router: Router,
    public platform: Platform,
    public fcmService: FcmService,
    public afs: AngularFirestore,
    public ngswUpdate: NgswUpdateService,
    public modalCtrl: ModalController
  ) {
      this.ngswUpdate.checkForUpdate().then(() => void 'zoggy');
      // impossible d'utiliser l'event platform.resume sur PWA
      setInterval(() => {
        this.ngswUpdate.checkForUpdate().then(() => void 'zoggy');
      },30000); // toutes les 30 s

    platform.resize.subscribe(async () => {
      if (this.isAuthenticated) {
        this.displayBottomBar();
      }
    });
    this.setLanguages();
    this.initializeApp();
  }

  // @HostListener('window:popstate', ['$event'])
  // async closeAllModal() {
  //   const modal = await this.modalCtrl.getTop();
  //   const alert = await this.alertCtrl.getTop();
  //   console.log(this.modalCtrl);
  //   if (modal) {
  //     await this.modalCtrl.dismiss();
  //     return false;
  //   } else if (alert) {
  //     await this.alertCtrl.dismiss();
  //     return false;
  //   }
  // }

  findPage(pagesList: Array<AppPage>): boolean {
    const result = pagesList.find((p)=> p.url === this.router.url);
    if (this.router.url.includes('gestion')) {
      return false;
    } else {
      return !result;
    }
  }

  displayBottomBar() {
    const width = this.platform.width();
    if (width >= 576) {
      this.showBottomBar = false;
    } else {
      this.showBottomBar = true;
    }
  }

  async setLanguages() {
    this.translate.addLangs(['en', 'fr']);
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');


    await this.storageService.isReady();
    let lang = await this.storageService.get('preferedLang');
    if (!lang) {
      lang = 'fr';
    }
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(lang);
    dayjs.locale(this.translate.currentLang);
    dayjs.extend(localizedFormat);
  }

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
    this.authSubscription.unsubscribe();
  }

  initializeApp() {
    const login$ = this.authService.isLoggedIn();
    const isOnline$ = this.authService.networkStatus();
    this.authSubscription.add(this.authService.toastStatus());
    this.authSubscription.add(combineLatest([login$, isOnline$]).subscribe(([authUser, online]) => {
      if(this.initialisedOffline && online) {
        // reload if app initialised offline
        window.location.reload();
      }
      if (authUser) {
        this.uid = authUser.uid;
        if (online && !this.isAuthenticated && authUser.emailVerified) {
          // Get user, courses, etab informations
          this.subscription = this.getData();
          if (this.platform.is('cordova')) {
            this.fcmService.enableNotif(this.uid);
          } else {
            this.notificationService.enableNotif(this.uid);
          }

        }
        if (this.appInit && !online) {
          this.initialisedOffline = true;
          // reload if offline
          this.showAlertInitWhileOffline();
        }
      } else {
        this.menu.enable(false);
        this.showBottomBar = false;
        this.isAuthenticated = false;
        if(this.subscription) {
          this.subscription.unsubscribe();
        }
      }
      this.appInit = false;
    }));

    // pas de check version pour le moment
    /*    if (isCordovaPlatform) {
          this.checkAppVersion();
        }*/
  }

  /*checkAppVersion() {
    Promise.all([this.afs.doc<{mandatoryVersion: number}>('/apps/android').ref.get(), this.appVersion.getVersionCode()])
      .then(values => {
        const mandatoryVersion = values[0].data().mandatoryVersion;
        const myVersion = values[1];
        if (myVersion < mandatoryVersion) {
          // Todo: translate
          this.updateAlert('' + this.translate.instant('home.UPDATE_MSG.HEADER'),
            '' + this.translate.instant('home.UPDATE_MSG.MSG_NATIVE')).then(() => {
              if (this.platform.is('android')) {
                window.open('https://play.google.com/store/apps/details?id=com.stellasurgical.egraft');
                // this.market.open('com.stellasurgical.egraft'); On l'utilise pas car Huawei à son propre store
              } else if (this.platform.is('ios')) {
                // TODO: Avec le plugin cordova ou non
                // this.market.open('egraft');
              }
              this.exitApp();

          });
        }
      });

  }*/
  getData(): Subscription {
    const user$ = this.globalService.user$;
    const etab$ = this.globalService.legalEntity$;
    const chatsViews$ = this.storageService.chatsViewsValueChanges();
    const chatRooms$ = this.globalService.allMessages$;
    return combineLatest([user$, etab$, chatRooms$, chatsViews$]).subscribe(([user, etab,  rooms, chatsViews]) => {
      this.menu.enable(true);
      this.displayBottomBar();
      this.isAuthenticated = true;
      this.user = user;
      this.userRole = user.role;
      this.newMsgs = this.checkForNewMsg(rooms, chatsViews);
      this.pagesList = this.appPagesCoorPrelev;

      if (etab) {
        this.etabNom = etab.rs;
        this.etabLicensed = etab.licenced;
/*        if (etab.id === 'ABM') {
          this.abmUser = true;
          this.appPagesCoorGreff.splice(2, 1);
        }*/
      } else {
        this.etabNom = '';
        this.etabLicensed = true;
      }
    }, error => {
      if (error.message === 'USER_NO_ETAB') {
        this.showAlertDoesntBelongEtab();
      }
    });
  }

  showAlertDoesntBelongEtab() {
    // TODO : TRANSLATION
    this.presentAlert('Accès refusé',
      'Votre compte n\'est associé à aucun établissement de santé. Veuillez vous adresser à un gestionnaire de votre établissement.');
    this.onSignOut();
  }

  async showAlertInitWhileOffline() {
    await this.presentAlert(this.translate.instant('pmo.ERROR_NETWORK.HEADER'),
      this.translate.instant('pmo.ERROR_NETWORK.MESSAGE'));
    window.location.reload();
  }

  goToTuto() {
    this.navCtrl.navigateRoot('/tuto');
  }

  onSignOut() {
    this.authService.doLogout().then(() => {
      this.navCtrl.navigateRoot('/login');
    });
  }

  async presentAlert(header: string, message: string) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK'],
      cssClass: 'foo'
    });
    await alert.present();
    return alert.onDidDismiss();
  }

  async updateAlert(header: string, message: string) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['' + this.translate.instant('pmo.UPDATE_MSG.BTN')],
    });
    await alert.present();
    return alert.onDidDismiss();
  }

  exitApp() {
    (navigator as any).app.exitApp();
  }

  /*async openNotifPopover(event) {
    const popover = await this.popoverController.create({
      component: NotificationGestionComponent,
      componentProps: {
        userId: this.uid,
        userRole: this.userRole
      },
      backdropDismiss: false,
      mode: 'ios'
    });
    popover.onDidDismiss().then((dataReturned) => {
      if (dataReturned.data !== undefined && dataReturned.data.action === 'showHistory') {
        this.openHistoryPopover(event, dataReturned.data.fullNotifs);
      }
    });

    return await popover.present();
  }*/

  /*async openHistoryPopover(event, notifsList) {
    const popover = await this.popoverController.create({
      event,
      cssClass: 'NotifPopover',
      component: HistoryNotificationComponent,
      componentProps: {
        userId: this.uid,
        userRole: this.userRole,
        notifsList
      },
      mode: 'ios',
      animated: false
    });
    popover.onDidDismiss().then((dataReturned) => {
      if (dataReturned.data.action === 'goBack') {
        this.openNotifPopover(event);
      }
    });

    return await popover.present();
  }*/

  navigate(url) {
    return this.navCtrl.navigateRoot(url);
  }
  move(){
    this.removeActiveClassFromAllMenuButtons();
  }
  removeActiveClassFromAllMenuButtons(){
    const menuDivs = document.getElementsByClassName('container-icon-label');
    if(menuDivs) {
      // @ts-ignore
      for (const div of menuDivs) {
        for (const element of div.children) {
          element.classList.remove('deep-active');
        }
      }
    }
  }

  stopScan = () => {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    document.body.classList.remove('qrscanner'); // remove the qrscanner from the body
  };

  checkForNewMsg(rooms: Message[][], chatsViews: { [p: string]: Date }) {
    return rooms.some(room => {
      const courseId = room[0].courseId;
      const initalMsg = room[0];
      // On va regarder le dernier vu de l'utilisateur par rapports à tous les messages et notices, sauf les notices de l'utilisateur.
      const msgs = room.filter((m) => m.type === 'USER_MSG' || m.type === 'ACTION_NOTICE' && m.userId !== this.uid);
      // trouver le tout dernier message et le dernier message de l'utilisateur
      const lastMsg = msgs.length ? msgs[msgs.length - 1] : initalMsg;
      const onlyMyMsgs = msgs.filter(msg => msg.userId === this.uid);
      const myLastMsg = onlyMyMsgs[onlyMyMsgs.length - 1];
      // il y a un nouveau message ?
      return !isChatSeen(chatsViews[courseId], myLastMsg?.date, lastMsg.date);
    });
  }
}

