import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './shared/auth.guard';
import {UnauthGuard} from './shared/unauth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pmo',
    pathMatch: 'full'
  },
  {
    path: 'pmo',
    canActivate: [AuthGuard],
    // TODO : Réactiver le TutoGuard quand un nouveau tuto sera fait.
    // canActivate: [AuthGuard, TutoGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages-home/pmo/pmo.module').then(m => m.pmoPageModule)
      },
      {
        path: 'course-suivi-global/:idDonor',
        loadChildren: () => import('./pages-course/course-suivi-global/course-suivi-global.module').then(m => m.CourseSuiviGlobalPageModule)
      },
      {
        path: 'course-suivi/:idDonor/:idCourse',
        loadChildren: () => import('./pages-course/course-suivi/course-suivi.module').then(m => m.CourseSuiviPageModule)
      }
    ]
  },
  {
    path: 'login',
    canActivate: [UnauthGuard],
    loadChildren: () => import('./pages-home/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'login-mdp-oublie',
    canActivate: [UnauthGuard],
    loadChildren: () => import('./pages-home/login-mdp-oublie/login-mdp-oublie.module').then(m => m.LoginMdpOubliePageModule)
  },
  // TODO : Réactiver le TutoGuard quand un nouveau tuto sera fait.
/*  {
    path: 'tuto',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages-home/tuto/tuto.module').then(m => m.TutoPageModule)
  },*/
  {
    path: 'gestion',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages-gestion/gestion/gestion.module').then(m => m.GestionPageModule)
      },
      {
        path: 'personnel',
        loadChildren: () => import('./pages-gestion/personnel/personnel.module').then(m => m.GestionPersonnelPageModule)
      },
      {
        path: 'transport',
        loadChildren: () => import('./pages-gestion/transport/transport.module').then(m => m.GestionTransportPageModule)
      },
      {
        path: 'stats',
        loadChildren: () => import('./pages-gestion/stats/stats.module').then(m => m.GestionStatsPageModule)
      },
      {
        path: 'mon-compte',
        // TODO : Réactiver le TutoGuard quand un nouveau tuto sera fait.
        loadChildren: () => import('./pages-gestion/mon-compte/mon-compte.module').then(m => m.GestionMonComptePageModule)
      },
      {
        path: 'parametre',
        loadChildren: () => import('./pages-gestion/parametre/parametre.module').then(m => m.GestionParametrePageModule)
      },
/*      {
        path: 'upgrade',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages-gestion/upgrade/upgrade.module').then(m => m.GestionUpgradePageModule)
      },*/
      {
        path: 'invites',
        loadChildren: () => import('./pages-gestion/invites/invites.module').then(m => m.GestionInvitesPageModule)
      },
      {
        path: 'about',
        loadChildren: () => import('./pages-gestion/about/about.module').then( m => m.AboutPageModule)
      },
      {
        path: 'contact',
        loadChildren: () => import('./pages-gestion/contact/contact.module').then( m => m.ContactPageModule)
      }
    ]
  },
  {
    path: 'course-create',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'prelevement',
        pathMatch: 'full'
      },
      {
        path: 'prelevement',
        loadChildren: () => import('./pages-course/course-create/course-create.module').then(m => m.CourseCreatePageModule)
      },
      {
        path: 'greffe',
        loadChildren: () => import('./pages-course/course-create/course-create.module').then(m => m.CourseCreatePageModule)
      },
      {
        path: 'update',
        loadChildren: () => import('./pages-course/course-create/course-create.module').then(m => m.CourseCreatePageModule)
      },
    ]
  },
  {
    path: 'pmo-archive',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages-course/pmo-archive/pmo-archive.module').then(m => m.CourseListGlobalPageModule)
      },
      {
        path: 'course-suivi-global/:idDonor',
        loadChildren: () => import('./pages-course/course-suivi-global/course-suivi-global.module').then(m => m.CourseSuiviGlobalPageModule)
      },
      {
        path: 'course-suivi/:idDonor/:idCourse',
        loadChildren: () => import('./pages-course/course-suivi/course-suivi.module').then(m => m.CourseSuiviPageModule)
      },
    ]
  },
  {
    path: 'course-chat-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages-course/course-chat-list/course-chat-list.module').then(m => m.CourseChatListPageModule)
  },
  {
    path: 'cgu',
    loadChildren: () => import('./pages-gestion/cgu/cgu.module').then(m => m.CguPageModule)
  },
  {
    path: 'forbidden',
    loadChildren: () => import('./pages-home/forbidden/forbidden.module').then(m => m.ForbiddenPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
