import {Injectable} from '@angular/core';
import firebase from 'firebase/compat/app';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {first, map, skip} from 'rxjs/operators';
import {fromEvent, merge, Observable, of} from 'rxjs';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from './toast.service';
@Injectable({
    providedIn: 'root'
})

export class AuthService {
    // private callable: (data: any) => Observable<any>;
    constructor(public afAuth: AngularFireAuth, private fns: AngularFireFunctions,
                public toastService: ToastService, public translate: TranslateService) {
        // fonction au rebut
        //this.callable = fns.httpsCallable('sendEmail');
    }
    isLoggedIn(): Observable<firebase.User | null> {
        return this.afAuth.authState;
    }
    async doLogin(value, isRememberMe) {
        await this.afAuth.setPersistence(isRememberMe ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION);
        return this.afAuth.signInWithEmailAndPassword(value.email.toLowerCase().trim(), value.password);
    }

    doLogout() {
        return this.afAuth.signOut();
    }

    getAuthUser(): Promise<firebase.User> {
        return this.afAuth.authState.pipe(first()).toPromise();
    }
    /*composeEmail(to: Array<string>, templateName: string, data: {login?: string; password?: string; sponsorName?: string;
        sponsorEtab?: string; sponsorRole?: string; link?: string;}) {
        return this.callable({
            to,
            template: {
                name: templateName,
                data
            }
        });
    }*/

    toastStatus() {
      return merge(
        of(null),
        fromEvent(window, 'online'),
        fromEvent(window, 'offline')
      ).pipe(skip(1), map(() => navigator.onLine)).subscribe(online => {
        if (!online) {
          this.toastService.showToast(this.translate.instant('erreur.NO_INTERNET'), 'danger', 0);
        } else {
          this.toastService.showToast(this.translate.instant('erreur.INTERNET_BACK'),
            'success', 3000);
        }
      });
    }

    networkStatus() {
      return merge(
        of(null),
        fromEvent(window, 'online'),
        fromEvent(window, 'offline')
      ).pipe(map(() => navigator.onLine));
    }
}
