export const environment = {
  production: true,
  platform: 'pwa',
  firebase: {
    apiKey: 'AIzaSyAzUQVkribaEM_DlS-95dOx1LX5_DJvAi4',
    authDomain: 'e-graft-prod.firebaseapp.com',
    databaseURL: 'https://e-graft-prod.firebaseio.com',
    projectId: 'e-graft-prod',
    storageBucket: 'e-graft-prod.appspot.com',
    messagingSenderId: '705394565661',
    appId: '1:705394565661:web:4eecb654a539df9c761cf4'
  },
  recaptcha: {
    siteKey: '6LfgvC4pAAAAAB2Rkcrk8ElpNMBrgtTuyBcoVT4c',
  },
  vapidKey: 'BEtEtV3NHFbeKhzBMnqdu0SISWE-CmVr3zENVhjJpqbhjWajAVoaWFqVocwJweaoRbrfIifvENFDJfOUqDGeogs',
  registrationURL: 'https://registration.e-graft.com/',
  continueURL: 'https://e-graft-prod.firebaseapp.com/',
};
