import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from './services/auth.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UnauthGuard implements CanActivate {
    constructor(public authService: AuthService, public router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | boolean
      | UrlTree {
        return new Promise(async (resolve, reject) => {
            try {
                const user = await this.authService.getAuthUser();
              if (user && user.emailVerified) {
                reject('Page unauthorised while logged');
                this.router.navigateByUrl('/pmo');
              } else {
                resolve(true);
              }
            } catch (error) {
                reject(error);
            }
        });
    }
}
