import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ToolbarComponentPageModule} from './components/toolbar-component/toolbar-component.module';
import {DateFormatPipe} from './pipes/date-format.pipe';
import {DateTimeShortFormatPipe} from './pipes/date-time-short-format.pipe';
import {DurationFormatPipe} from './pipes/duration-format.pipe';
import {CustomTimeFormatPipe} from './pipes/custom-time-format.pipe';
import { DigitOnlyDirective } from './directives/digit-only.directive';
import { AlphabetOnlyDirective } from './directives/alphabet-only.directive';

@NgModule({
    imports: [
        CommonModule
    ],
  declarations: [
    DateFormatPipe, DateTimeShortFormatPipe, DurationFormatPipe, CustomTimeFormatPipe, DigitOnlyDirective, AlphabetOnlyDirective
  ],
  exports: [
    CommonModule, IonicModule, FormsModule, RouterModule, TranslateModule,
    ReactiveFormsModule, ToolbarComponentPageModule, DateFormatPipe, DateTimeShortFormatPipe,
    DurationFormatPipe, CustomTimeFormatPipe, DigitOnlyDirective, AlphabetOnlyDirective
  ]
})
  export class SharedModule {
}
