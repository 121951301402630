import { Injectable } from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NgswUpdateService {

  constructor(private swUpdate: SwUpdate, private alertController: AlertController, private translate: TranslateService) {
    if(swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(evt => {
        if(evt.type === 'VERSION_READY') {
          this.presentAlert();
        }
      });
    }
  }
  checkForUpdate(): Promise<boolean> {
    return this.swUpdate.isEnabled ? this.swUpdate.checkForUpdate() : Promise.resolve(false);
  }

  private async presentAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('pmo.UPDATE_MSG.HEADER'),
      message: this.translate.instant('pmo.UPDATE_MSG.MSG_PWA'),
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    window.location.reload();
  }
}
